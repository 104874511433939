import React from 'react'

export default function Footer() {
  return (    
    <>
    <div className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-6 col-md-6">
                    <h4 className="text-primary mb-4">Our Office</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt text-primary me-3"></i>1/8, Mahatma Phule C.H.S. Ltd.<br/> Pant Nagar, Ghatkopar(E), Mumbai-400075</p>
                    {/* <p className="mb-2"><i className="fa fa-phone-alt text-primary me-3"></i>+012 345 67890</p> */}
                    <p className="mb-2"><i className="fa fa-envelope text-primary me-3"></i>info@example.com</p>
                  
                </div>
                <div className="col-lg-6 col-md-6">
                    <h4 className="text-primary mb-4">Quick Links</h4>
                    <a className="btn btn-link" href="/about-us">About Us</a>
                    <a className="btn btn-link" href="/contact-us">Contact Us</a>
                    <a className="btn btn-link" href="/services">Our Services</a>                    
                <a className="btn btn-link" href="/brands">Brands</a>
                </div>
               
            </div>
        </div>
    </div>  
    
    <div className="container-fluid copyright py-4">
        <div className="container">
            <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <a className="fw-medium" href="#">GloryOverseas</a>, All Right Reserved.
                </div>
             
            </div>
        </div>
    </div>
    <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up"></i></a>
    </>
  )
}
