import React from 'react'
import { Link } from 'react-router-dom';

export default function header() {
  return (    
    <div className="container-fluid bg-white sticky-top">
        <div className="container">
            <nav className="navbar navbar-expand-lg bg-white navbar-light py-2 py-lg-0">
                <Link to="/" className="navbar-brand">
                    <img className="img-fluid" src="/assets/img/glory-logo.png" alt="Logo"/>
                </Link>
                <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto">
                        <Link to="/" className="nav-item nav-link active">Home</Link>
                        <Link to="/about-us" className="nav-item nav-link">About</Link>
                        <Link to="/services" className="nav-item nav-link">Services</Link>
                        <Link to="/brands" className="nav-item nav-link">Brands</Link>
                    
                        <Link to="/contact-us" className="nav-item nav-link">Contact</Link>
                    </div>
                  
                </div>
            </nav>
        </div>
    </div>
   
  )
}
