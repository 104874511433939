import React, { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';

export default function ContactUs() {
    
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm('service_8djdrxl', 'template_30yj4us', event.target, 'Qfv04eXqVscF0yYpC')
            .then(
                () => {
                    setStatus('SUCCESS! Your message has been sent.');
                    setFormData({
                        from_name: '',
                        from_email: '',
                        subject: '',
                        message: ''
                    });
                },
                (error) => {
                    setStatus(`FAILED... ${error.text}`);
                }
            );
    };

    return (
        <div>
            <Helmet>
        <title>Contact-us - Glory Overseas</title>
        <meta name="description" content="Premier Distributor of Trusted Food Brands" />
        <meta name="keywords" content="Brands, services, glory overseas" />
        <link rel="canonical" href="https://www.gloryoverseas.in/services" />
      </Helmet>
            <div className="container-fluid page-header py-1 wow fadeIn" data-wow-delay="0.1s">
                <div className="container text-center py-1">
                    <h1 className="fs-1 text-light mb-4 animated slideInDown">Contact Us</h1>
                </div>
            </div>

            <div className="container-xxl contact py-5">
                <div className="container">
                    <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ 'maxWidth': '500px' }}>
                        <h1 className="fs-4">If You Have Any Query, Please Contact Us</h1>
                    </div>
                    <div className="row g-5 mb-5">
                        {/* Contact Info Columns */}
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h3 className="mb-4">Enquiry</h3>
                            <form id="contact-form" onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"                                             
                                                name="from_name"
                                                placeholder="Your Name"
                                                value={formData.from_name}
                                                required
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"                                               
                                                name="from_email"
                                                placeholder="Your Email"
                                                value={formData.from_email}
                                                required
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="subject"
                                                name="subject"
                                                placeholder="Subject"
                                                value={formData.subject}
                                                required
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Leave a message here"
                                                id="message"
                                                style={{ height: '120px' }}
                                                value={formData.message}
                                                name="message"
                                                required
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {status && <p>{status}</p>}
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="h-100">
                                <iframe className="w-100 rounded"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d942.6208008582164!2d72.91467032838733!3d19.086446598877863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c633726cc619%3A0x29bf73a8104982ed!2s185%2C%20Pant%20Nagar%20Colony%20Rd%2C%20Naidu%20Colony%2C%20Pant%20Nagar%2C%20Ghatkopar%20East%2C%20Mumbai%2C%20Maharashtra%20400075!5e0!3m2!1sen!2sin!4v1725184699320!5m2!1sen!2sin"
                                        frameBorder="0" style={{ 'height': '100%', 'minHeight': '300px', 'border': '0' }} allowFullScreen="" aria-hidden="false"
                                        tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
