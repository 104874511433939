import logo from './logo.svg';
import './App.css';
import Header from './MyComponents/Header';
import Footer from './MyComponents/Footer';
import Home from './MyComponents/Home';
import AboutUs from './MyComponents/AboutUs';
import Services from './MyComponents/Services';
import Brands from './MyComponents/Brands';
import ContactUs from './MyComponents/ContactUs';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {

  return (   
    <Router>   
    <Header/>       
    <Routes>
        <Route path="/" element={<Home />} />
       
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    <Footer/>
    </Router>
  );
}

export default App;
