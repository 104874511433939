import React, { useEffect } from 'react';

export default function Services() {
    useEffect(() => {
        document.title = 'Services - Glory Overseas';
    });
  return (
    <div>
<div class="container-fluid page-header py-1  wow fadeIn" data-wow-delay="0.1s">
    <div class="container text-center py-1">
        <h1 class="fs-1 text-light mb-4 animated slideInDown">Services</h1>
     
    </div>
</div>

<div class="container-xxl py-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-lg-5 wow fadeIn " data-wow-delay="0.1s">
                <img class="img-fluid " src="/assets/img/services.png" alt=""/>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <ul>
                    <li class="mb-2"> Distribution service in General trade, Modern trade and Horeca trade.</li>
                    <li class="mb-2"> We offer a delightful Gift Hamper service for festivals, creating moments of joy and sweetness with carefully curated selections that add a touch of delight to your special occasions.</li>
                    <li class="mb-2"> Also, selling on a leading online platform, for a convenient and secure shopping experience.</li>
                    <li class="mb-2"> We currently have access to spacious warehouse where things are kept dry & in excellent order, accounting staff, the advanced computer hardware, internet compatibility.</li>
                    <li class="mb-2"> Expanding our horizons, we are thrilled to announce our new venture into import and export services.</li>
                </ul>
            </div>
        </div>
    </div>
</div>

    </div>
  )
}
