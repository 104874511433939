import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
    // useEffect(() => {
    //     document.title = 'About Us - Glory Overseas';
        
    // });
  return (
    <div>
        <Helmet>
        <title>About Us - Glory Overseas </title>
        <meta name="description" content="We aim to win over more taste-buds than ever before" />
        <meta name="keywords" content="About us, about glory overseas" />
        <link rel="canonical" href="https://www.gloryoverseas.in/about-us" />
      </Helmet>
<div className="container-fluid page-header py-1 mb-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container text-center py-1">
        <h1 className="fs-1 text-light mb-4 animated slideInDown">About Us</h1>
       
    </div>
</div>

<div className="container-xxl contact py-2">
    <div className="container">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
           
            <h1 className="fs-3">We aim to win over more taste-buds than ever before</h1>
        </div>
        <div className="row justify-content-center wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-10">
                <p className="justify-content-center mb-3"> Welcome to ‘Glory Overseas’, your go-to source for bringing fantastic flavours straight to your local stores. We're not just a food distribution business; we're your partners in creating a tastier and more exciting shopping experience.</p>
                <p className="justify-content-center mb-3"> Since 2014, Glory Overseas has been successfully doing business in the Indian market. Our team of skilled, youthful, and adaptable individuals has distributed a variety of branded food products to prominent institutions, modern trade, general trade outlets and other locations around India.</p>
                <p className="justify-content-center mb-3"> Here at Glory Overseas, we cater to your various demands with our passion for providing outstanding food distribution service. We are committed to enhancing lives and building lasting relationships through our commitment to delivering high-quality, reliable, and sustainable food products.</p>
            </div>
        </div>
    </div>
</div>
<div className="container-fluid testimonial py-1 my-1">
    <div className="container py-5">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
            <h1 className="fs-3 text-light">Why work with Glory Overseas?</h1>
        </div>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                        <img className="img-fluid" src="/assets/img/choose-img.jpg" alt=""/>
                    </div>
                    <div className="col-lg-6 text-light wow fadeIn" data-wow-delay="0.5s">
                        <ul>
                            <li className="mb-1"> Glory Overseas has a solid reputation for being trustworthy and reliable. Our clients can count on us to deliver quality products and services consistently.</li>
                            <li className="mb-1"> We've earned the trust of well-known brands including Sapphire, My Fitness, Slurrp Farm and others, showing that we meet high standards and deliver on our promises. This trust is a testament to our credibility in the industry.</li>
                            <li> We value personal connections with our clients and partners. Building strong relationships allows us to understand their needs better and tailor our services to meet those specific requirements.</li>
                            <li> We take pride in ensuring that our deliveries are always on time. This reliability is crucial for our clients who depend on us to keep their operations running smoothly.</li>
                            <li> Our business operations are transparent, and we take great care to provide accurate information. This commitment to clarity builds trust with our clients, making sure they are well-informed and confident in our services</li>
                            </ul>
                        
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="container-xxl py-1">
    <div className="container">
        <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
        
            <div className="border-top mb-4"></div>
            <div className="row g-3 mb-4">
                <div className="col-sm-4">
                    <img className="img-fluid bg-white w-100" src="/assets/img/story.jpg" alt="our story"/>
                </div>
                <div className="col-sm-8">
                    <h5>Our Story</h5>
                    <p className="mb-2">At Glory Overseas, our story is all about making sure you have access to the most delicious and diverse range of food products. We started because we believe that everyone deserves to enjoy the best snacks and treats, and we're on a mission to make that happen in your neighborhood.</p>
                    <p className="mb-2">Love convenience? So do we! Shop your favourite snacks effortlessly. We've made it super simple for you to get your hands on the latest and greatest treats without any hassle with our outstanding distribution service</p>
                </div>
            </div>
            <div className="border-top mb-4"></div>
            <div className="row g-3 mb-4">
                <div className="col-sm-8">
                    <h5>Our mission</h5>
                    <p className="mb-0">Empowering businesses and consumers by delivering a wide range of food products efficiently, fostering sustainability, and ensuring customer satisfaction through exceptional service and integrity in every aspect of our distribution process</p>
                </div>
                <div className="col-sm-4">
                    <img className="img-fluid bg-white w-100" src="/assets/img/mission.jpg" alt="mission"/>
                </div>
            </div>
            <div className="border-top mb-4"></div>
            <div className="row g-3 mb-4">
                <div className="col-sm-4">
                    <img className="img-fluid bg-white w-100" src="/assets/img/vision.jpg" alt="vision"/>
                </div>
                <div className="col-sm-8">
                    <h5>Our vision</h5>
                    <p className="mb-2">To be the leading provider of high-quality food products, connecting communities through a diverse and reliable distribution network</p>

                </div>
            </div>
        </div>
    </div>
</div>
<div className="container-fluid testimonial py-1 my-1">
    <div className="container py-1">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
            {/* <h1 className="display-6 text-light">Managing Director</h1> */}
        </div>
        <div className="testimonial-item-full p-4 p-lg-5">
            <p className="mb-2">The driving force behind Glory Overseas is the founder, Mr Pradeep Balu. With over 30 years of working in the field of food products marketing, our founder brings a wealth of experience to the table.</p>
            <p className="mb-2">His expertise is pivotal asset to our business, a reservoir of knowledge cultivated over three decades in marketing food products</p>
            <p className="mb-2">His smart decisions and clear direction motivates our team, guiding us towards success in the ever-changing world of food distribution. </p>
            <p className="mb-2">
                Our founder not only leads with expertise but also cultivates strong relationships with all stakeholders. His ability to connect and collaborate creates a harmonious ecosystem.
            </p>
            <div className="d-flex align-items-center justify-content-center">
                <div className="text-start ms-3">
                    <h5>Mr. Pradeep Balu</h5>
                    <div className="text-center">
                        <span className="text-primary ">Founder</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="testimonial-item-full p-4 p-lg-5">
            <p className="mb-2">Jatin Balu, the seasoned Operations Head at Glory Overseas, bringing a wealth of knowledge and expertise to our dynamic team.</p>
            <p className="mb-2">Armed with a degree in Business Management, Jatin possesses a strong academic foundation that seamlessly blends with his hands-on experience in the food distribution landscape. </p>
            <p className="mb-2">Whether implementing cutting-edge inventory management systems or fine-tuning our delivery protocols, he is at the forefront of enhancing our overall operational efficacy. </p>
            <p className="mb-2">
                He makes sure that every distribution is a seamless and satisfying experience for our valued customers.
            </p>
            <div className="d-flex align-items-center justify-content-center">
                <div className="text-start ms-3">
                    <h5>Mr. Jatin Balu</h5>
                    <div className="text-center">
                        <span className="text-primary ">Operations Head</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    </div>
  )
}
