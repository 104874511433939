import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export default function Home() {
    useEffect(() => {
        // document.title = 'Home - Glory Overseas';
        // Ensure jQuery is available globally
        if (window.$) {
          // Initialize Owl Carousel
          window.$(".product-carousel").owlCarousel({
            autoplay: true,
            smartSpeed: 1000,
            margin: 25,
            loop: true,
            center: true,
            dots: false,
            nav: true,
            navText : [
                '<i class="bi bi-chevron-left"></i>',
                '<i class="bi bi-chevron-right"></i>'
            ],
            responsive: {
                0:{
                    items:1
                },
                576:{
                    items:1
                },
                768:{
                    items:2
                },
                992:{
                    items:3
                }
            }
        });
        }
      }, []);
    return (
   <>
   <Helmet>
        <title>Home - Glory Overseas</title>
        <meta name="description" content="Welcome to Glory Overseas, your go-to source for bringing fantastic flavours straight to your local stores. We're not just a food distribution business; we're your partners in creating a tastier and more exciting shopping experience" />
        <meta name="keywords" content="Home, glory overseas" />
        <link rel="canonical" href="https://www.gloryoverseas.in/" />
      </Helmet>
    <div className="container-fluid px-0 mb-5">
 <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
        <div className="carousel-item active">
                <img className="w-100" src="/assets/img/brandHeaderImages/royal-dansk-cookies.jpg" alt="royal-dansk-cookies"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/sapphire.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/slurrup-4.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/my-fitnes-2.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/only-1.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/fresh-melts.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/Heineken.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/helth-fields.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/impactint.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/jacker.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/mille.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/only-2.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/tiffany.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="/assets/img/brandHeaderImages/pokoloko.jpg" alt="Image"/>
                <div className="carousel-caption">
                    <div className="container">
                        <div className="row justify-content-center">
                          
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
            </div>
            </div> 
    <div className="container-xxl contact">
             <div className="container">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
            <h1 className="fs-2">Welcome to Glory Overseas</h1>

        </div>
        <div className="col-md-12">            
            <p className="fs-7 fw-medium">Welcome to Glory Overseas, your go-to source for bringing fantastic flavours straight to your local stores. We're not just a food distribution business; we're your partners in creating a tastier and more exciting shopping experience.</p>
        </div>
       </div>
     </div>
     <div className="container-fluid product py-5 my-5">
    <div className="container py-5">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
           
            <h1 className="fs-5">Brands We Represent</h1>
        </div>
        <div className="owl-carousel product-carousel wow fadeInUp" data-wow-delay="0.5s">
            <a href="/brands" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/Sapphire.png" alt="Sapphire"/>
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Sapphire</h4>
                    <span className="text-body">A Magical & Delicious collection of premium confectionery</span>
                </div>
            </a>
            <a href="/brands" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/heineken-logo-1.jpg" alt="heineken-logo"/>
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Heineken</h4>
                    <span className="text-body">Heineken 0.0 non-Alcoholic Beer is crafted with the same passion </span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/royal-dansk-logo.png" alt="royal-dansk-logo"/>
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Royal Dansk</h4>
                    <span className="text-body">Royal Dansk Butter Cookies from Denmark, are the crispy</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/impactints.png" alt="impactints" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Impact mints</h4>
                    <span className="text-body">Superbly flavoured mints for a super fresh and ever</span>
                </div>
            </a>

            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/jacker.png" alt="jacker" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Jacker</h4>
                    <span className="text-body">Jacker Potata Crips & Jacker Cube bite-size, crunchy cream </span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/oriental.png" alt="oriental" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Oriental</h4>
                    <span className="text-body">Oriental - Ready & Tasty Snack Cheese Balls & Super Rings</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/pokoloko.png" alt="pokoloko" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Poko Loko</h4>
                    <span className="text-body"> Poko Loko - INDIA'S FIRST NFT DRINK Chewy and delicious fruity</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/riter-sport.png" alt="riter-sport" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Ritter Sports</h4>
                    <span className="text-body">Ritter Sports offers your favourite chocolate with the best</span>
                </div>
            </a>
          
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/tiffany.png" alt="tiffany"/>
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Tiffany</h4>
                    <span className="text-body">Tiffany's Crunchy velvety smooth and crunchy wafer</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/slurrp-farm.png" alt="slurrp-farm" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Slurrp Farm</h4>
                    <span className="text-body">The leading provider of best multigrain healthy and delicious</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/my-fitness.png" alt="my-fitness" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">My Fitness</h4>
                    <span className="text-body">My Fitness - India's 1st U.S.FDA Registered Peanut Butter Brand</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/fresh-melts.png" alt="fresh-melts" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Fresh Meltz</h4>
                    <span className="text-body">INDIA’S FIRST ORAL HYGIENE FRESH BREATH STRIPS that melts</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/mille.png" alt="mille" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Mille</h4>
                    <span className="text-body">SuperblyMille - Break Fast Pancakes, Breakfast Cereals</span>
                </div>
            </a>
         
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/only.png" alt="only" />
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Only</h4>
                    <span className="text-body">Only - premium range of Gourmet Herbs,Spices</span>
                </div>
            </a>
            <a href="" className="d-block product-item rounded">
                <img src="/assets/img/brandsLogoImages/health-fields.png" alt="health-fields"/>
                <div className="bg-white shadow-sm text-center p-4 position-relative mt-n5 mx-4">
                    <h4 className="text-primary">Health Fields</h4>
                    <span className="text-body">Health Fields - India's Leading Organic Food Brand</span>
                </div>
            </a>

        </div>
    </div>
</div>
<div class="container-xxl py-5">
    <div class="container">
        <div class="row g-5">
            <div class="col-lg-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                <img class="img-fluid" src="/assets/img/choose-img.jpg" alt="choose-img"/>
            </div>
          
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="section-title">
                  
                    <h1 class="fs-3">Why work with Glory Overseas?</h1>
                </div>
                <p class="mb-3"> Glory Overseas has a solid reputation for being trustworthy and reliable. Our clients can count on us to deliver quality products and services consistently.</p>
                <p class="mb-3"> We've earned the trust of well-known brands including Sapphire, My Fitness, Slurrp Farm and others, showing that we meet high standards and deliver on our promises. This trust is a testament to our credibility in the industry.</p>
                <p class="mb-3">  We value personal connections with our clients and partners. Building strong relationships allows us to understand their needs better and tailor our services to meet those specific requirements.</p>
                <p class="mb-3">  We take pride in ensuring that our deliveries are always on time. This reliability is crucial for our clients who depend on us to keep their operations running smoothly.</p>
                <p class="mb-3">  Our business operations are transparent, and we take great care to provide accurate information. This commitment to clarity builds trust with our clients, making sure they are well-informed and confident in our services</p>

                <Link to="/about-us" class="btn btn-primary rounded-pill py-3 px-5">Read More</Link>
            </div>
        </div>
    </div>
</div>
</>
  )
}
