import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Brands() {
    // useEffect(() => {
    //     document.title = 'Brands - Glory Overseas';
    // });
  return (
    <div>
        <Helmet>
        <title>Brands - Glory Overseas</title>
        <meta name="description" content="Premier Distributor of Trusted Food Brands" />
        <meta name="keywords" content="Brands, services, glory overseas" />
        <link rel="canonical" href="https://www.gloryoverseas.in/services" />
      </Helmet>
<div className="container-fluid page-header py-1  wow fadeIn" data-wow-delay="0.1s">
    <div className="container text-center py-1">
        <h1 className="fs-1 text-light mb-4 animated slideInDown">Brands we Represent</h1>

    </div>
</div>

<div className="container-xxl py-5">
    <div className="container">
        <div className="section-title text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{'max-width':'500px'}}>
            <h1 className="fs-5">Premier Distributor of Trusted Food Brands</h1>
        </div>
        <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/royal-dansk-logo.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/royal-dansk-cookies.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Royal Dansk Butter Cookies from Denmark, are the crispy, buttery delicaciese, deeply rooted in Danish baking traditions, using only the finest ingredients since 1966.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/Sapphire1.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/sapphire-1.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                    A Magical &amp; Delicious collection of premium confectionery.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandsLogoImages/fresh-melts.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandHeaderImages/fresh-melts.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">Fresh Meltz  - INDIA’S FIRST ORAL HYGIENE FRESH BREATH STRIPS that melts on your tongue instantly for quick cleansing and freshness.</p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/health-fields.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/helth-fields.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Health Fields - India's Leading Organic Food Brand that offers a wide range of organic products like Pulses, Flours, Millets, Spices, Sugar & Salt, Seeds & Nuts, Rice & Cereals,
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/heineken-logo-1.jpg" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/Heineken.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Heineken 0.0 non-Alcoholic Beer is crafted with the same passion and quality as the original
                        beer, ensuring you get the authentic taste you love without the alcohol.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/impactints.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/impactint.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Superbly flavoured mints for a super fresh and ever pleasant breath from Germany
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/jacker.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/jacker.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Jacker Potata Crips & Jacker Cube bite-size, crunchy cream filled wafer cubes
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/oriental.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/oriental.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Oriental - Ready & Tasty Snack Cheese Balls & Super Rings
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/pokoloko.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/pokoloko1.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Poko Loko - INDIA'S FIRST NFT DRINK
                        <strong> Chewy and delicious fruity drink Nata De Coco</strong>
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/riter-sport.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/ritter-sport.png" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Ritter Sports offers your favourite chocolate with the best ingredients for every taste: fine milk, crunchy hazelnuts, honey salted almonds, cornflakes, fine marzipan.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

        
           

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/tiffany.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/tiffany.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Tiffany - Tiffany's Crunchy velvety smooth and crunchy wafer biscuits & Sugar -Free Sandwich Cream Biscuits offering a sweet sublime taste are all the way from UAE.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/slurrp-farm.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/slurrup.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Slurrp Farm - The leading provider of best multigrain healthy and delicious food for kids toddlers in India.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/my-fitness.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/my-fitnes.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        My Fitness - India's 1st U.S.FDA Registered Peanut Butter Brand made from high-quality, roasted peanuts and does not contain any artificial ingredients, making it a great option for health enthusiasts
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/mille.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/mille.jpg" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        Mille - Break Fast Pancakes, Breakfast Cereals, Cake & Brownie mixes, Gluten Free Grains, Protein Pastas made using super grains (like millets, lentils and legumes) that are gluten-free, high-protein, fibre-rich, and inherently climate-smart
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>

            <hr />
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">

                    <img className="img-fluid" src="/assets/img/brandsLogoImages/only.png" alt=""/>

                    <div className="store-overlay">

                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <img className="img-fluid" src="/assets/img/brandHeaderImages/only.png" alt=""/>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="store-item position-relative text-center">
                    <p className="py-1 fs-6">
                        On1y - premium range of Gourmet Herbs, Spices and Seasoning blends for the richness of a discerning palate in all its exclusivity, capturing the essence of herbs and spices in grinder and sprinkler jars.
                    </p>
                    <div className="store-overlay">
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

    </div>
  )
}
